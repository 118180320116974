import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import  { CERTIFICATES }  from  "../../../gql/certificate";
import {Card, CardHeader, Col, Input,  Row  } from 'reactstrap';
import Students from '../../../Components/Common/StudentCertificate';
import aaetav from "../../../assets/images/logo-aaetav.png";

const SearchResults = () => {

    const [documentNumber, setDocumentNumber] = useState("");
    

    const handleSubmit = (event) => {
        event.preventDefault();
        setDocumentNumber(event.target.documentNumber.value);
        event.target.documentNumber.value="";
       
     }

     
 
const {data,  loading,  error, startPolling, stopPolling}= useQuery (CERTIFICATES, {
    variables: {
        filters: {"documentNumber": {"contains": documentNumber}},
       
    },
    
} );
 
  
  if (loading)  return  <Col lg={12}>
  <h5 className="fs-16 fw-semibold text-center mb-0">Buscando Certificados ...</h5>
</Col>
  
       
   if (error) return <pre>Error Buscando Certificados::: {error.message}</pre>
 
  const {certificates} = data;

 
document.title="Validador de Certificados  Emitidos por AAETAV";

    return (
        <React.Fragment>
            
            <div class="col d-flex align-self-center justify-content-center">    
           <img src={aaetav} alt="" className="img-thumbnail " />
            </div>
           <form  className="search-form"  onSubmit={  ev => handleSubmit(ev)}>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader className="border-0">
                                    <Row className="justify-content-center mb-4">
                                        <Col lg={6}>
                                            <Row className="g-2">
                                                <Col>
                                                    <div className="position-relative mb-3">
                                                        <Input type="text" className="form-control form-control-lg bg-light border-light"  minLength={8} placeholder="ingrese un  número de documento sin puntos" defaultValue=""  id="documentNumber"/>
                                                        
                                                    </div>
                                                </Col>
                                                <div className="col-auto">
                                                    <button type="submit" class="btn btn-primary btn-lg waves-effect waves-light"><i className="mdi mdi-magnify me-1"></i> Buscar</button>
                                                </div>
                                            </Row>
                                        </Col>
                                        
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <Students  certificates={certificates}/>
                                        </Col>
                                    </Row>
         
                                </CardHeader>
          </Card>
          </Col>
          </Row>
       
           </form>
        </React.Fragment>
    );
};

export default SearchResults;