import React, { useState } from 'react';
import { Table } from "reactstrap";
import moment from 'moment/moment';



const Students = (props) => {

     const {certificates} = props;

      
     const [validDate, setValidDate] = useState(null);


   const handlerValidDate = (certificate) =>{
        
    if (certificate.attributes.certificateValidDate  === null) 
        setValidDate(certificate.attributes.certificateDependency.data.attributes.certificateValidDate)
    else
        setValidDate(certificate.attributes.certificateValidDate)
    
       }
  

    function getCurrentDate(endDate){
        var fechaInicio = moment(new Date());
        var fechaFin    = moment(endDate);
        
        var diff = fechaFin.diff(fechaInicio, 'days')
       
        return diff ;
       }

 if (certificates !== null)
  return (
    
    <React.Fragment>
       
 <div className="table-responsive">
 
<Table className="table-striped table-nowrap align-middle mb-0" >
    <thead>
       
        <tr>
        <th scope="col">Nombre</th>
            <th scope="col">Apellido</th>
            <th scope="col">Curso</th>
            <th scope="col">Código</th>
            <th scope="col">Provincia</th>
            <th scope="col">Estado</th>
            <th scope="col">Vencimiento</th>
            
        </tr>
    </thead>
    <tbody>
  
      
       {certificates?.data.map((st) =>
       
        <tr key={st.id.toString()}>
               <td className="fw-medium">{st.attributes.studentFirstName}</td>
               <td className="fw-medium">{st.attributes.studentSurename}</td>
               <td className="fw-medium">{st.attributes.courseTitle}</td>
               <td className="fw-medium">{st.attributes.code}</td>
               <td className="fw-medium">{st.attributes.courseCity}</td>
               
               {(getCurrentDate(st.attributes.certificateValidDate) > 180 && st.attributes.code !=="" ) ?
               <>
                <td><span className="badge bg-success text-black">VIGENTE </span></td>
                 <td><span className="fw-medium">{st.attributes.certificateValidDate} </span></td>
                </>
              : 
             (getCurrentDate(st.attributes.certificateValidDate) > 0 && st.attributes.code !=="") ?
             <>
              <td><span className="badge bg-warning text-black">POR VENCER</span></td>
              <td><span className="fw-medium">{st.attributes.certificateValidDate} </span></td>
              </>
             :  
             <>
             <td><span className="badge bg-danger text-black">VENCIDO</span></td>
             <td><span className="fw-medium">{st.attributes.certificateValidDate} </span></td>
             </>
               }
              
            

           </tr>
                
             
           )}
    </tbody>
</Table>
</div>

     </React.Fragment>
   
)
else
return   <div class="col d-flex align-self-center justify-content-center"> Sin resultados</div>
  }

export default Students;
  