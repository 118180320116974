import React from "react";
import { gql } from "@apollo/client";

export const CREATE_CERTIFICATE = gql`

  mutation createCertificate($data: CertificateInput!){
 createCertificate (data: $data){
  data{
    attributes{
      certificateValidDate
      valid
      code
      student {
        data {
          id
        }
      }
      course {
        data {
          id
        }
      }
        certificatePDF{
          data {
            id
          }
        } 
        
       certificateDependency{
        data{
          id
        }
      }
    }
  }
}
}

`;

export const UPDATE_CERTIFICATE = gql`
  
   mutation updateCertificate($id:ID!, $data: CertificateInput!){
 createCertificate (id:$id, data: $data){
  data{
    attributes{
      certificateValidDate
      valid
      code
      student {
        data {
          id
        }
      }
      course {
        data {
          id
        }
      }
        certificatePDF{
          data {
            id
          }
        } 
        
       certificateDependency{
        data{
          id
        }
      }
    }
  }
}
}
 
`;

/*
export const DELETE_CERTIFICATE = gql`
  {
   
  }

`;
*/


export const CERTIFICATE = gql`
 query certificate($id:ID!){
   certificate(id:$id){
    
  data{
    id
    attributes{
      certificateValidDate
      valid
      code
      student {
        data {
          id
          attributes{
            firstName
            surename
            documentType
            documentNumber
          }
        }
      }
      course {
        data {
          id
        }
      }
       
        
       certificateDependency{
        data{
          id
        }
      }
    }
  }
}
}
`;

export const CERTIFICATES = gql`
 query certificates($filters:CertificateFiltersInput){
   certificates(filters:$filters){
  data{
    id
    attributes{
      certificateValidDate
      code
      documentNumber
      studentSurename
      studentFirstName
      courseTitle
      courseCity
      courseFromDate
      courseToDate
      certificateDependency{
        data {
          id
          attributes {
            certificateValidDate
          }
        }
      }
     }
  }
  }
}
  `;

