import React from "react";
import { Navigate } from "react-router-dom";

import SearchResults from '../pages/Pages/SearchResults/SearchResults';

const publicRoutes = [
  
  {
    path: "/",
    exact: true,
    component: <SearchResults />,
  },
  
]


export { publicRoutes };